// src/pages/Contact.tsx
import React from 'react';
import styled from 'styled-components';
import { FaEnvelope, FaDiscord, FaYoutube, FaTwitter, FaTiktok, FaTwitch, FaCoffee } from 'react-icons/fa';

const ContactContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  color: #1877f2;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const ContactInfo = styled.div`
  background-color: #f0f2f5;
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const EmailAddress = styled.a`
  font-size: 1.5rem;
  color: #1877f2;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;

  &:hover {
    text-decoration: underline;
  }
`;

const EmailIcon = styled(FaEnvelope)`
  margin-right: 10px;
`;

const SocialLinks = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
`;

const SocialLinkItem = styled.li`
  margin-bottom: 1rem;
`;

const SocialLink = styled.a`
  color: #1877f2;
  text-decoration: none;
  font-size: 1.2rem;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;

const SocialIcon = styled.span`
  margin-right: 10px;
  font-size: 1.5rem;
`;

const Contact: React.FC = () => {
  const socialLinks = [
    { platform: 'Discord', url: 'https://discord.gg/h3wxa5zY7X', icon: <FaDiscord /> },
    { platform: 'YouTube', url: 'https://www.youtube.com/@DavidMadeThis_YouTube', icon: <FaYoutube /> },
    { platform: 'Twitter', url: 'https://twitter.com/DavidMadeThis', icon: <FaTwitter /> },
    { platform: 'TikTok', url: 'https://www.tiktok.com/@david_made_this', icon: <FaTiktok /> },
    { platform: 'Twitch', url: 'https://www.twitch.tv/david_made_this', icon: <FaTwitch /> },
    { platform: 'Ko-fi', url: 'https://ko-fi.com/davidmadethis', icon: <FaCoffee /> },
  ];

  return (
    <ContactContainer>
      <Title>Contact Me</Title>
      <ContactInfo>
        <EmailAddress href="mailto:david@davidmadethis.net">
          <EmailIcon />
          david@davidmadethis.net
        </EmailAddress>
        <p>Feel free to reach out via email or connect with me on social media!</p>
      </ContactInfo>
      <SocialLinks>
        {socialLinks.map((link, index) => (
          <SocialLinkItem key={index}>
            <SocialLink href={link.url} target="_blank" rel="noopener noreferrer">
              <SocialIcon>{link.icon}</SocialIcon>
              {link.platform}
            </SocialLink>
          </SocialLinkItem>
        ))}
      </SocialLinks>
    </ContactContainer>
  );
};

export default Contact;
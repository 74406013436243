// src/projectsData.ts
import powerNetworkTycoonImage from './images/power-network-tycoon.jpeg';
import explosionArenaImage from './images/explosion-arena.jpg';
import cooperImage from './images/cooper.jpg';
import theRitualImage from './images/the-ritual.jpg';
import gameBreakImage from './images/game-break.png';
import powerNetworkImage from './images/power-network.jpg';
import dailyStockbotImage from './images/daily-stockbot.jpg';
import hydrationStationImage from './images/hydration-station.jpg';

export interface Project {
    id: number;
    title: string;
    icon?: string;
    description: string;
    fullDescription: string;
    image: string;
    link: string;
    externalLink: string;
    privacyPolicy: string;
}

export const projects: Project[] = [
    {
        id: 1,
        title: 'Power Network Tycoon',
        icon: '🗺️',
        description: 'A physics based Power Engineering City Builder game.',
        fullDescription: 'Manage a power network on a growing island. Use industry simulation methods to handle electricity demands, weather challenges and ensure safety. Developed by a Power Engineer, it offers realistic electricity management gameplay, combining strategy and real-world engineering challenges.',
        image: powerNetworkTycoonImage,
        link: '/projects/power-network-tycoon',
        externalLink: 'https://store.steampowered.com/app/2429930/Power_Network_Tycoon/',
        privacyPolicy: `Privacy Policy for Power Network Tycoon: See Steam page for details`
    },
    {
        id: 2,
        title: 'Cooper',
        icon: '🧩',
        description: 'A multiplayer RPG with monsters, swords and magic.',
        fullDescription: 'Cooper is an action RPG set in a time when corruption is overtaking the land. Adventure solo or team up with a friend to fight back against evil.',
        image: cooperImage,
        link: '/projects/cooper',
        externalLink: 'https://davidmadethis.itch.io/cooper',
        privacyPolicy: `Privacy Policy for Cooper:
<br><br>
1. Information Collection: We collect basic user data such as game progress and high scores to enhance your gaming experience.
<br><br>
2. Data Usage: Your data is used solely for game functionality and is not shared with any third parties.
<br><br>
3. Data Security: We employ robust security measures to protect your information from unauthorized access.
<br><br>
4. User Control: You can request to view, edit, or delete your data at any time through the in-game settings.
<br><br>
5. Policy Updates: This privacy policy may be updated occasionally. Please review it periodically for any changes.
<br><br>
If you have any questions about our privacy practices, please contact us at contact.davidmadethis@gmail.com.`
    },
    {
        id: 3,
        title: 'The Ritual',
        icon: '🚀',
        description: 'A Journaling and meditation app.',
        fullDescription: 'Journaling and meditation have become increasingly popular forms of self-care in the modern world. This app provides a unique and meditative experience that allows you to truly process your thoughts and feelings. The app is designed to give you a sense of liberation and peace by allowing you to cast all of your thoughts into a virtual fire.',
        image: theRitualImage,
        link: '/projects/the-ritual',
        externalLink: 'https://play.google.com/store/apps/details?id=com.DavidMadeThis.TheRitual&pcampaignid=web_share',
        privacyPolicy: `Privacy Policy for The Ritual:

This privacy policy is in reference to the meditation and journalling app "The Ritual" developed by DavidMadeThis. This app does not collect any journal information entered and this is not used for advertising purposes, however Google Ads does collect information as per their Privacy Policy at https://support.google.com/adspolicy/answer/54817?hl=en-AU.
<br><br>
1. Acknowledgment and Acceptance of Terms
<br><br>
DavidMadeThis is committed to protecting your privacy. This Privacy Statement sets forth our current privacy practices with regard to the information we collect when you or your computer interact with The Ritual. By accessing The Ritual, you acknowledge and fully understand our Privacy Statement and freely consent to the information collection and use practices described in this Website Privacy Statement.
<br><br>
2. Participating Clients, Merchant Policies, and Third Party Websites
<br><br>
Related services and offerings with links from this website, including all other websites, have their own privacy statements that can be viewed by clicking on the corresponding links within each respective website. DavidMadeThis is not responsible for the privacy practices or contents of third-party or client websites. We recommend and encourage that you always review the privacy policies of merchants and other third parties before you provide any personal information or complete any transaction with such parties.
<br><br>
3. Information We Collect and How We Use It
<br><br>
This app uses Google Ads' free conversion tracking features on certain pages. If you contact us online, the destination page will have code on it that will help us understand the path you took to arrive on that page.
<br><br>
DoubleClick: We use Google Ads remarketing codes to log when users view specific pages or take specific actions on a website. This allows us to provide targeted advertising in the future. If you do not wish to receive this type of advertising from us in the future you can opt out using the DoubleClick opt-out page or the Network Advertising Initiative opt-out page.
<br><br>
We will not disclose personally identifiable information we collect from you to third parties without your permission except to the extent necessary including:
To fulfill your requests for services.
To protect ourselves from liability.
To use in marketing and advertising.
To respond to legal process or comply with law, or in connection with a merger, acquisition, or liquidation of the company.
<br><br>
4. Changes to This Statement
<br><br>
DavidMadeThis has the discretion to occasionally update this privacy statement. We encourage you to periodically review this privacy statement to stay informed about how we are helping to protect the personal information we collect.
<br><br>
5. Contacting Us
<br><br>
If you have questions regarding our Privacy Statement, its implementation, failure to adhere to this Privacy Statement and/or our general practices, please contact us at contact.davidmadethis@gmail.com`
    },
    {
        id: 4,
        title: 'Game Break (Discontinued)',
        icon: '🧩',
        description: 'A Physiotherapy app for Gamers.',
        fullDescription: "Developed in consultation with an Esports Physiotherapist this app allows you to choose your play time, receive alerts when its time to take a break, and provides you with easy exercises to do during the break to prevent stiffness, pain and injuries from prolonged gaming. It will also track your total play time, so you can see how long you've been playing for!",
        image: gameBreakImage,
        link: '/projects/game-break',
        externalLink: 'https://play.google.com/store/apps/details?id=com.DavidMadeThis.GameBreak',
        privacyPolicy: `Privacy Policy for Game Break: Discontinued`
    },
    {
        id: 5,
        title: 'Power Network',
        icon: '🧩',
        description: 'Transmission line thermal simulation based on electrical physics.',
        fullDescription: 'Transmission line thermal simulation based on electrical physics.',
        image: powerNetworkImage,
        link: '/projects/power-network',
        externalLink: 'https://davidmadethis.itch.io/power-network',
        privacyPolicy: `Privacy Policy for Power Network:
<br><br>
1. Information Collection: We collect basic user data such as game progress and high scores to enhance your gaming experience.
<br><br>
2. Data Usage: Your data is used solely for game functionality and is not shared with any third parties.
<br><br>
3. Data Security: We employ robust security measures to protect your information from unauthorized access.
<br><br>
4. User Control: You can request to view, edit, or delete your data at any time through the in-game settings.
<br><br>
5. Policy Updates: This privacy policy may be updated occasionally. Please review it periodically for any changes.
<br><br>
If you have any questions about our privacy practices, please contact us at contact.davidmadethis@gmail.com.`
    },
    {
        id: 6,
        title: 'Daily Stockbot (Discontinued)',
        icon: '🧩',
        description: 'Daily updates on companies list on the ASX and NYSE.',
        fullDescription: 'Daily Stockbot monitors the ASX and NYSE to assess updates for companies and make predictions for their short-term movement based off sentiment analysis.',
        image: dailyStockbotImage,
        link: '/projects/daily-stockbot',
        externalLink: 'https://www.google.com',
        privacyPolicy: `Privacy Policy for Daily Stockbot: Discontinued`
    },
    {
        id: 7,
        title: 'Explosion Arena (Discontinued)',
        icon: '🧩',
        description: 'Make the largest explosion you can in the arena.',
        fullDescription: 'Make the largest explosion you can in the arena. Your score is based on the challenge faced by your device and your score records your name and device model.',
        image: explosionArenaImage,
        link: '/projects/explosion-arena',
        externalLink: 'https://www.google.com',
        privacyPolicy: `Privacy Policy for Explosion Arena: Discontinued`
    },
    {
        id: 8,
        title: 'Hydration Station (Discontinued)',
        icon: '🧩',
        description: 'Record your daily water consumption to grow plants.',
        fullDescription: 'Record your daily water consumption to grow plants by watering them. Sell the plants to earn money to buy new seeds.',
        image: hydrationStationImage,
        link: '/projects/hydration-station',
        externalLink: 'https://www.google.com',
        privacyPolicy: `Privacy Policy for Hydration Station: Discontinued`
    },
];
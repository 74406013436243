// src/pages/Portfolio.tsx
import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { projects as projectsData } from '../projectsData';

const PortfolioContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  color: #1877f2;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
`;

const ProjectCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ProjectImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ProjectInfo = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center; // Center children horizontally
`;

const ProjectTitle = styled.h3`
  color: #1c1e21;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
`;

const ProjectDescription = styled.p`
  color: #606770;
  margin-bottom: 1rem;
`;

const ProjectLink = styled(Link)`
  display: inline-block;
  background-color: #1877f2;
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.2s;

  &:hover {
    background-color: #166fe5;
  }
`;

const Portfolio: React.FC = () => {
  return (
    <PortfolioContainer>
      <Title>My Portfolio</Title>
      <ProjectGrid>
        {projectsData.map((project) => (
          <ProjectCard key={project.id}>
            <ProjectImage src={project.image} alt={project.title} />
            <ProjectInfo>
              <ProjectTitle>{project.title}</ProjectTitle>
              <ProjectDescription>{project.description}</ProjectDescription>
              <ProjectLink to={project.link}>View Project</ProjectLink>
            </ProjectInfo>
          </ProjectCard>
        ))}
      </ProjectGrid>
    </PortfolioContainer>
  );
};

export default Portfolio;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { FaGamepad, FaCode, FaMobileAlt, FaBolt, FaDiscord, FaTwitter, FaYoutube, FaTiktok, FaTwitch, FaCoffee } from 'react-icons/fa';
import { Helmet } from 'react-helmet';

const HomeContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
`;

const Hero = styled.div`
  background: linear-gradient(135deg, #6e8efb, #a777e3);
  border-radius: 16px;
  padding: 4rem 2rem;
  margin-bottom: 3rem;
  text-align: center;
  color: white;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  font-size: 3.5rem;
  margin-bottom: 1rem;
  font-weight: 700;
`;

const Subtitle = styled.p`
  font-size: 1.5rem;
  margin-bottom: 2rem;
  opacity: 0.9;
`;

const CTAButton = styled(Link)`
  display: inline-block;
  background-color: white;
  color: #6e8efb;
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

const Section = styled.section`
  background-color: white;
  border-radius: 16px;
  padding: 3rem;
  margin-bottom: 3rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
  text-align: center;
`;

const ProjectCard = styled.div`
  background-image: url('/images/power-network-tycoon.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg, rgba(246, 211, 101, 0.8), rgba(253, 160, 133, 0.8));
    z-index: 1;
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  }
`;

const ProjectContent = styled.div`
  position: relative;
  z-index: 2;
`;

const ProjectIcon = styled.div`
  font-size: 4rem;
  margin-bottom: 1rem;
  color: white;
`;

const ProjectTitle = styled.h3`
  color: white;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const ProjectDescription = styled.p`
  color: white;
  font-size: 1.2rem;
  opacity: 0.9;
`;

const ViewAllButton = styled(Link)`
  display: block;
  width: max-content;
  margin: 3rem auto 0;
  background-color: #6e8efb;
  color: white;
  padding: 1rem 2rem;
  border-radius: 30px;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.2rem;
  transition: all 0.3s ease;

  &:hover {
    background-color: #5c7cfa;
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
`;

const SkillsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-top: 2rem;
`;

const SkillCard = styled.div`
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 2rem;
  text-align: center;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
`;

const SkillIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 1rem;
  color: #6e8efb;
`;

const SkillTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #333;
`;

const SkillDescription = styled.p`
  font-size: 1rem;
  color: #666;
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
`;

const SocialIcon = styled.a`
  color: #6e8efb;
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: #5c7cfa;
  }
`;

const Home: React.FC = () => {
  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "David",
    "url": "https://davidmadethis.net",
    "jobTitle": "Power Engineer and Software Developer",
    "description": "Crafting innovative software solutions with a focus on power engineering, game development, and web applications",
    "sameAs": [
      "https://discord.gg/h3wxa5zY7X",
      "https://www.youtube.com/@davidmadethis_youtube",
      "https://twitter.com/DavidMadeThis",
      "https://www.tiktok.com/@david_made_this",
      "https://www.twitch.tv/David_Made_This"
    ]
  };

  return (
    <HomeContainer>
      <Helmet>
        <title>David Made This | Power Engineering & Software Solutions</title>
        <meta name="description" content="David's portfolio: Innovative power engineering and software solutions. Explore projects in power systems, game development, and web applications." />
        <meta name="keywords" content="power engineering, software development, game development, web applications, innovative solutions" />
        <meta name="author" content="David" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://davidmadethis.net" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <Hero>
        <Title>Welcome to David Made This</Title>
        <Subtitle>Innovative solutions in Power Engineering and Software Development</Subtitle>
        <CTAButton to="/portfolio">Explore My Work</CTAButton>
      </Hero>
      <Section id="about">
        <SectionTitle>About Me</SectionTitle>
        <p>
          Hello! I'm David, a passionate power engineer and software developer with a unique blend of skills in both fields. With over 13 years of experience in the power industry and 5 years in software development, I've had the pleasure of working on a diverse range of projects, from complex power system simulations to customized business applications.
        </p>
        <p>
          My journey began with a deep fascination for power systems and a desire to understand engineering and physics in depth. I have formal qualifications in power engineering, mathematics, and physics. This led me to pursue a career in power engineering, where I've developed expertise in power system design, construction, commissioning, maintenance, and operations. Alongside my engineering career, I discovered a passion for software development, which has allowed me to create innovative games and applications that bridge the gap between my interests and modern technology.
        </p>
        <p>
          What sets me apart is my ability to blend technical expertise in power engineering with creative software solutions. I believe that the future of energy lies at the intersection of traditional power systems and cutting-edge software. This philosophy drives me to create software and games that bring the complexities of power engineering to the mainstream gaming market. Through my interests in science, engineering, and entertainment, I've been able to bring ideas to life that not only solve real-world problems but also educate and engage users in the fascinating world of power systems.
        </p>
        <p>
          My multidisciplinary approach allows me to tackle challenges from various angles, whether it's optimizing power grid efficiency through advanced algorithms or developing interactive simulations that make complex engineering concepts accessible to a wider audience. I'm constantly exploring new technologies and methodologies to stay at the forefront of both the power engineering and software development fields.
        </p>
      </Section>
      <Section>
        <SectionTitle>My Expertise</SectionTitle>
        <SkillsGrid>
          <SkillCard>
            <SkillIcon><FaBolt aria-hidden="true" /></SkillIcon>
            <SkillTitle>Power Engineering</SkillTitle>
            <SkillDescription>Extensive experience in power system analysis, design, construction, and operations, with a focus on creating efficient and sustainable energy solutions. Specializing in smart grid technologies, renewable energy integration, and power system optimization.</SkillDescription>
          </SkillCard>
          <SkillCard>
            <SkillIcon><FaGamepad aria-hidden="true" /></SkillIcon>
            <SkillTitle>Game Development</SkillTitle>
            <SkillDescription>Creating immersive gaming experiences using Unity, Godot, and Unreal Engine, with a focus on physics-based simulations and innovative gameplay mechanics. Expertise in developing educational games that make complex engineering concepts fun and accessible.</SkillDescription>
          </SkillCard>
          <SkillCard>
            <SkillIcon><FaCode aria-hidden="true" /></SkillIcon>
            <SkillTitle>Web Development</SkillTitle>
            <SkillDescription>Building responsive and dynamic web applications using modern frameworks like React, ensuring seamless user experiences across devices. Skilled in creating interactive data visualization tools for power system analysis and monitoring.</SkillDescription>
          </SkillCard>
          <SkillCard>
            <SkillIcon><FaMobileAlt aria-hidden="true" /></SkillIcon>
            <SkillTitle>Mobile App Development</SkillTitle>
            <SkillDescription>Developing cross-platform mobile applications for various industries, including utility management apps, energy consumption trackers, and augmented reality tools for field engineers.</SkillDescription>
          </SkillCard>
        </SkillsGrid>
      </Section>
      <Section>
        <SectionTitle>Featured Project</SectionTitle>
        <ProjectCard>
          <ProjectContent>
            <ProjectIcon><FaGamepad aria-hidden="true" /></ProjectIcon>
            <ProjectTitle>Power Network Tycoon</ProjectTitle>
            <ProjectDescription>A physics-based Power Engineering City Builder game that challenges players to create and manage complex power networks while balancing economic and environmental factors. This game combines real-world power system principles with engaging gameplay, offering both entertainment and educational value.</ProjectDescription>
          </ProjectContent>
        </ProjectCard>
        <ViewAllButton to="/portfolio">View All Projects</ViewAllButton>
      </Section>
      <Section>
        <SectionTitle>Let's Connect</SectionTitle>
        <p>
          I'm always excited to collaborate on new projects, share knowledge, or discuss the latest developments in power engineering and software development. Whether you're interested in my work, have a project idea, or just want to chat about the future of energy and technology, I'd love to hear from you.
        </p>
        <p>
          Feel free to reach out through any of the social media platforms below or visit my contact page for more ways to get in touch. Let's work together to create innovative solutions that power the future!
        </p>
        <SocialLinks>
          <SocialIcon href="https://discord.gg/h3wxa5zY7X" target="_blank" rel="noopener noreferrer" aria-label="Discord">
            <FaDiscord />
          </SocialIcon>
          <SocialIcon href="https://www.youtube.com/@davidmadethis_youtube" target="_blank" rel="noopener noreferrer" aria-label="YouTube">
            <FaYoutube />
          </SocialIcon>
          <SocialIcon href="https://twitter.com/DavidMadeThis" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <FaTwitter />
          </SocialIcon>
          <SocialIcon href="https://www.tiktok.com/@david_made_this" target="_blank" rel="noopener noreferrer" aria-label="TikTok">
            <FaTiktok />
          </SocialIcon>
          <SocialIcon href="https://www.twitch.tv/David_Made_This" target="_blank" rel="noopener noreferrer" aria-label="Twitch">
            <FaTwitch />
          </SocialIcon>
          <SocialIcon href="https://ko-fi.com/davidmadethis" target="_blank" rel="noopener noreferrer" aria-label="Ko-fi">
            <FaCoffee />
          </SocialIcon>
        </SocialLinks>
      </Section>
    </HomeContainer>
  );
};

export default Home;
import React from 'react';
import styled from 'styled-components';

const PrivacyPolicyContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const Title = styled.h1`
  color: #1877f2;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-align: center;
`;

const Section = styled.section`
  margin-bottom: 2rem;
`;

const SectionTitle = styled.h2`
  color: #1c1e21;
  font-size: 1.8rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  color: #606770;
  line-height: 1.6;
  margin-bottom: 1rem;
`;

const PrivacyPolicy: React.FC = () => {
  return (
    <PrivacyPolicyContainer>
      <Title>Privacy Policy</Title>
      <Section>
        <SectionTitle>1. Introduction</SectionTitle>
        <Paragraph>
          Welcome to David Made This. This privacy policy outlines how we collect, use, and protect your personal information when you use our website and services.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>2. Information We Collect</SectionTitle>
        <Paragraph>
          We may collect personal information such as your name, email address, and any other information you provide when contacting us or using our services.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>3. How We Use Your Information</SectionTitle>
        <Paragraph>
          We use the information we collect to provide and improve our services, communicate with you, and comply with legal obligations.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>4. Data Protection</SectionTitle>
        <Paragraph>
          We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>5. Your Rights</SectionTitle>
        <Paragraph>
          You have the right to access, correct, or delete your personal information. Please contact us if you wish to exercise these rights.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>6. Changes to This Policy</SectionTitle>
        <Paragraph>
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page.
        </Paragraph>
      </Section>
      <Section>
        <SectionTitle>7. Contact Us</SectionTitle>
        <Paragraph>
          If you have any questions about this privacy policy, please contact us through the contact form on our website.
        </Paragraph>
      </Section>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;